require('slick-carousel')

export default class LibSlicksWhyUsSlider {
  constructor() {
    setTimeout(this.slickInit, 0)
  }

  slickInit() {
    const whyUsSlider = $('.why-us-slider')
    if (!whyUsSlider || whyUsSlider.length === 0) return false
    if (whyUsSlider.get(0).classList.contains('slick-initialized')) return false

    whyUsSlider.slick({
      dots: true,
      mobileFirst: true,
      arrows: false,
      responsive: [{
        breakpoint: 1280,
        settings: 'unslick'
      }]
    })
  }
}
